// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-captcha-fail-js": () => import("../src/pages/captcha-fail.js" /* webpackChunkName: "component---src-pages-captcha-fail-js" */),
  "component---src-pages-contact-us-js": () => import("../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-contact-us-thankyou-js": () => import("../src/pages/contact-us/thankyou.js" /* webpackChunkName: "component---src-pages-contact-us-thankyou-js" */),
  "component---src-pages-how-it-works-js": () => import("../src/pages/how-it-works.js" /* webpackChunkName: "component---src-pages-how-it-works-js" */),
  "component---src-pages-index-js": () => import("../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-pricing-js": () => import("../src/pages/pricing.js" /* webpackChunkName: "component---src-pages-pricing-js" */),
  "component---src-pages-privacy-policy-js": () => import("../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-report-growth-prediction-js": () => import("../src/pages/report/growth-prediction.js" /* webpackChunkName: "component---src-pages-report-growth-prediction-js" */),
  "component---src-pages-report-map-js": () => import("../src/pages/report/map.js" /* webpackChunkName: "component---src-pages-report-map-js" */),
  "component---src-pages-report-market-profile-js": () => import("../src/pages/report/market-profile.js" /* webpackChunkName: "component---src-pages-report-market-profile-js" */),
  "component---src-pages-report-price-history-js": () => import("../src/pages/report/price-history.js" /* webpackChunkName: "component---src-pages-report-price-history-js" */),
  "component---src-pages-terms-and-conditions-js": () => import("../src/pages/terms-and-conditions.js" /* webpackChunkName: "component---src-pages-terms-and-conditions-js" */),
  "component---src-pages-who-is-it-for-js": () => import("../src/pages/who-is-it-for.js" /* webpackChunkName: "component---src-pages-who-is-it-for-js" */),
  "component---src-pages-why-use-it-js": () => import("../src/pages/why-use-it.js" /* webpackChunkName: "component---src-pages-why-use-it-js" */)
}

